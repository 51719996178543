import { useState } from 'react';
import { format } from 'date-fns';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import Portal from '@mui/material/Portal';

import { getInputProp } from 'libs/input';
import CustomActionBar from './CustomActionBar';

/**
 * @returns {{ defaultMinDate: Date, defaultMaxDate: Date }}
 */
const getDefaultYearRange = () => {
  const currentYear = new Date().getFullYear();

  return {
    defaultMinDate: new Date(currentYear - 10, 0, 1),
    defaultMaxDate: new Date(currentYear + 10, 11, 31),
  };
};

/**
 * @typedef {Object} BaseRegularDatePickerProps
 * @property {'small' | 'medium'} [size]
 * @property {boolean} [fullWidth] 
 * @property {string} [inputPropName] 
 * @property {Object} [inputProps]
 * @property {string} [name]
 * @property {boolean} [required]
 * @property {string} [label='日期']
 * @property {Date} value 
 * @property {function(Date): void} onChange 
 * @property {Date} [minDate] 
 * @property {Date} [maxDate]
 * @property {boolean} [disabled]
 */

/**
 * #NOTES:  
 * ClickAwayListener and Portal are used to repair the following warning:
 * Blocked aria-hidden on a <input> element because the element that just received focus must not be hidden from assistive technology users. Avoid using aria-hidden on a focused element or its ancestor.
 * 
 * @param {Omit<import('@mui/x-date-pickers').MobileDatePickerProps<Date>, keyof BaseRegularDatePickerProps | 'renderInput'> & BaseRegularDatePickerProps} props
 * @returns {JSX.Element}
 */
export default function RegularDatePicker(props) {
  const {
    size,
    fullWidth,
    inputPropName,
    inputProps,
    name,
    required,
    label = '日期',
    value = new Date(),
    onChange,
    minDate: userMinDate,
    maxDate: userMaxDate,
    disabled,
    ...restProps
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { defaultMinDate, defaultMaxDate } = getDefaultYearRange();

  // 使用者提供的日期範圍，如果沒有則使用預設值
  const minDate = userMinDate || defaultMinDate;
  const maxDate = userMaxDate || defaultMaxDate;

  /**
   * @returns {void}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * @param {Date | null} date 
   */
  const handleAccept = (date) => {
    if (date) {
      onChange(date);
      handleClose();
    }
  };

  /**
   * @param {Date | null} date 
   */
  const handleDateChange = (date) => {
    if (date) {
      onChange(date);
    }
  };

  /**
   * @param {Date} date 
   * @returns {string}
   */
  const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd');
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <TextField
          onClick={() => setIsOpen(true)}
          value={formatDate(value)}
          {...getInputProp(inputPropName)}
          fullWidth={fullWidth}
          size={size}
          name={name}
          required={required}
          label={label}
          {...inputProps}
          disabled={disabled}
        />
        {isOpen && (
          <Portal>
            <MobileDatePicker
              open={isOpen}
              onClose={handleClose}
              label={label}
              value={value}
              onChange={handleDateChange}
              onAccept={handleAccept}
              minDate={minDate}
              maxDate={maxDate}
              inputFormat="yyyy-MM-dd"
              openTo="year"
              views={['year', 'month', 'day']}
              toolbarFormat="yyyy-MM-dd"
              components={{
                ActionBar: CustomActionBar,
              }}
              {...restProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ display: 'none' }}
                />
              )}
              disablePortal
            />
          </Portal>
        )}
      </div>
    </ClickAwayListener>
  );
}